/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "~bootstrap/scss/bootstrap";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");



body>{
    font-family: 'Poppins';
    font-style: normal;
}